<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>企业管理</a-breadcrumb-item>
      <a-breadcrumb-item>账户管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">账户管理</span>
          <account v-if="activeKey === '1'"/>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">学员管理</span>
          <studentAccount v-if="activeKey === '4'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">在线账户</span>
          <live v-if="activeKey === '2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">在线学员</span>
          <student v-if="activeKey === '3'"/>
        </a-tab-pane>
        
      </a-tabs>
    </div>
  </div>
</template>

<script>
import live from './live'
import account from './account'
import student from './student'
import studentAccount from './studentAccount'

export default {
  name: 'Daily',
  components: {
    live,
    account,
    student,
    studentAccount
  },

  data() {
    return {
      activeKey: '1',
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>
