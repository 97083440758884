<template>
   <div>
    <div>
        <div class="clearfix table-tools">
        <div class="buttons">
        </div>
        <div class="search">
            <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>员工姓名/手机号</span>
                </template>
                <a-input v-model.trim="searchParams.search.realname" allowClear placeholder="员工姓名/手机号" style="width: 160px"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-select style="width:160px" v-model='searchParams.search.wid' @search="handleSearchApp" allowClear showSearch placeholder="请选择应用" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of appList" :key="index" :value="d['wid']">{{ d['app_name'] }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            </a-form>
        </div>
        </div>
        <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
            :columns="columns" :dataSource="list" @change="handleChange">
            <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="employee_name" slot-scope="text,record">
                {{text}}
            </template>
            <template slot="employee_active_time" slot-scope="text, record">
                <a @click="activeShow(record)">{{text}}</a>
            </template>
            <template slot="wechat" slot-scope="text, record">
                <svg style="width: 12px;height: 12px;margin-left: 5px;" aria-hidden="true">
                <use v-show="record.employee_wechat == 1" xlink:href="#icon-weixin"></use>
                <use v-show="record.employee_wechat == 0" xlink:href="#icon-weixin1"></use>
                </svg>
            </template>
            <template slot="action" slot-scope="text, record">
                <a v-if="record.access_token" href="javascript:;" v-clipboard:copy="record.access_token" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</a>
            </template>
        </a-table>
        </div>
        <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div>
    </div>
    <accessModal v-if="activeRecordShow" :item="item"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '应用名称', width: 120, dataIndex: 'app_name', key: 'app_name' , scopedSlots: { customRender: 'app_name' }},
  { title: '员工姓名', width: 120, dataIndex: 'employee_name', key: 'name' , scopedSlots: { customRender: 'employee_name' }},
  { title: '', width: 120, dataIndex: 'employee_day', key: 'employee_day' , scopedSlots: { customRender: 'employee_day' }},
  { title: '',width: 40, dataIndex: 'employee_wechat', key: 'employee_wechat' , scopedSlots: { customRender: 'wechat' }},
  { title: '性别', width: 74, dataIndex: 'employee_gender', key: 'age',align:'center'},
  { title: '工号', width: 74, dataIndex: 'job_number', key: 'job_number'},
  { title: '手机号码', width: 120, dataIndex: 'employee_cellphone', key: 'employee_cellphone' },
  { title: '在线时长', width: 120, dataIndex: 'employee_active_time', key: 'employee_active_time', scopedSlots: { customRender: 'employee_active_time' } },
  { title: '最后活跃', width: 120, dataIndex: 'last_active', key: 'last_active',sorter:()=>{}},
  { title: '账户状态', width: 120, dataIndex: 'employee_status', key: 'employee_status',sorter:()=>{}},
  { title: '操作', key: 'operation', fixed: 'right', width:80, scopedSlots: { customRender: 'action' }}
]
import tableMixins from '@/common/mixins/table'
import accessModal from './accessModal'
export default {
    name:'live',
    mixins: [ tableMixins ],
    components: {
        accessModal
    },
    data() {
        return {
            loading: false,
            activeRecordShow: false,
            columns,
            item: {},
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            list:[],
            appList:[],
        }
    },
    created () {
        this.getApp()
    },
    methods: {
        hideEditModal(){
            this.activeRecordShow = false;
        },
        activeShow(item){
            this.item = item
            this.activeRecordShow = true;
        },
        onCopy(){
            this.$message.success('已复制到剪切板!')
        },
        onError(){
            this.$message.error('操作失败!')
        },
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('enterpriseAccountLiveAction',{data:this.searchParams})
            this.list = res.items
            this.pageParams = res._meta
            this.loading = false
        },
        async getApp(val){
            await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
            .then((res)=>{
                this.appList = res.data
            })
        },
        handleSearchApp(val){
            this.getApp(val)
        },
        handleChange(pagination, filters, sorter){
            if(sorter.order){
                if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.field}`
                }else{
                    this.searchParams['sort'] = `-${sorter.field}`
                }
                }else{
                this.searchParams['sort'] = ''
                }
            this.getList()
        },
    },
}
</script>

<style lang="scss" scoped>

</style>