<template>
  <a-modal v-model="visible" :confirmLoading="confirmLoading" width="62.5%"  @cancel="handleCancel">
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
          :columns="columns" :dataSource="list">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="opt_content" slot-scope="text">
              <div v-html="text"></div>
            </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'80px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '应用名称', width:'80px', dataIndex: 'app_name', key: 'app_name'},
  { title: '访问类型', width:'80px', dataIndex: 'access_type', key: 'access_type',align:'center'},
  { title: '访问地址', width:'300px', dataIndex: 'referrer', key: 'referrer',ellipsis: true},
  { title: 'User-Agent', width:'100px', dataIndex: 'user_agent', key: 'user_agent',ellipsis: true}, 
  { title: '员工账户', width:'80px', dataIndex: 'employee_name', key: 'employee_name'},
  { title: '学员账户', width:'80px', dataIndex: 'student_name', key: 'student_name'},
  { title: 'IP地址', width:'120px', dataIndex: 'ip', key: '3' },
  { title: '操作时间', width:'120px', dataIndex: 'created_at', key: '4' },
]
import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import moment from 'moment'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'operation',
  inject: ['parent'],
  props: {
    item: Object
  },
  components: {
  },
  data() {
    return {
      loading: false,
      visible: false,
      confirmLoading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      operationTypes: [{ label: 'H5', value: 'h5' },{ label: 'QYEX-H5', value: 'qywx-h5' },{ label: 'WEB', value: 'web' }],
      accounts: [],
      appList: [],
      staffList: [],

      searchData: {},

      refundVisible: false,
      exportLoading: false,
      authType:['orginization','logging'],
    }
  },
  mixins: [ ranges , authority, tableMixins ],
  created() {
    this.visible=true;
    this.getApp()
    this.getStaff()
  },

  methods: {
    handleCancel() {
      this.visible=false;
      this.parent.hideEditModal(0)
    },
    filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    async getApp(val){
        await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
        .then((res)=>{
            this.appList = res.data
        })
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.searchParams.search.employee_id = this.item.employee_id
      this.searchParams.search.user_id = this.item.user_id
      let res = await this.$store.dispatch('enterpriseLoggingAccessAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleSearch(val){
        this.getStaff(val)
    },
    handleSearchApp(val){
      this.getApp(val)
    },
  }
}
</script>